import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Chip,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import { Clear, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import startOrJoinImg from '../../assets/onboardingPageThumbnail.svg';
import { ArrowDropDown } from '@mui/icons-material';
import no_data_img from '../../assets/no_data_img.svg';
import Navbar from '../../components/Header/Navbar';

export default function Dashboard({
  scrollToSection,
  featuresRef,
  whyChooseUsRef,
  pricingRef,
  homeRef,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const orgOption = [
    {
      id: 1,
      value: 'Owned',
    },
    {
      id: 2,
      value: 'Joined',
    },
  ];
  const url = window.location.href.split('?')[1]?.split('=')[1];
  const [orgData, setOrgData] = useState(null);
  const [joined, setJoined] = useState(0);
  const [owned, setOwned] = useState(0);
  const [org, setOrg] = useState(
    url ? url.charAt(0).toUpperCase() + url.slice(1) : orgOption[0].value
  );
  const [orgName, setOrgName] = useState('');
  const [orgError, setOrgError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const navigate = useNavigate();

  function validateName(e) {
    setOrgName(e);
    setIsSearchActive(e.length > 0);
    if (e.length === 0) {
      getOrgData();
      return;
    }
    setOrgError('');
    var re = new RegExp('^[a-zA-z_]+$');
    if (!re.test(e)) {
      setOrgError('Capital and small letters, along with underscores, are allowed');
    }
  }
  function handleClearSearch() {
    setOrgName('');
    setIsSearchActive(false);
    getOrgData();
  }

  const handleOrgPresentOrNot = (res) => {
    if (res.length === 0) {
      setOrgError('No organizations to show here');
    }
  };

  async function getOrgData() {
    setOrgError('');
    setOrgName('');
    try {
      setLoader(true);
      await organizationAPI.getOrganizations().then((res) => {
        setOrgData(res);
        setOwned(res.filter((x) => x.isCreator));
        setJoined(res.filter((x) => !x.isCreator));
        handleOrgPresentOrNot(res);
      });
      setLoader(false);
    } catch (err) {
      setOrgError(err.message);
      setLoader(false);
    }
  }

  async function getOrgSearch(name) {
    try {
      setLoader(true);
      const res = await organizationAPI.getOrganizations();
      const data = res.filter((x) => x.name.toLowerCase().includes(name.toLowerCase()));
      setOrgData(data);
      handleOrgPresentOrNot(data);
      setLoader(false);
    } catch (err) {
      setOrgError(err.message);
      setLoader(false);
    }
  }

  useEffect(() => {
    if (orgName === '') {
      getOrgData();
    }
  }, [orgName]);

  const handleCreateOrganization = () => {
    navigate('/onboarding?create=true');
    localStorage.setItem('onboardingStep', '1');
  };

  const handleJoinOrganization = () => {
    navigate('/onboarding?join=true');
    localStorage.setItem('onboardingStep', '-1');
  };

  return (
    <div className="main-wraper">
      <Navbar
        scrollToSection={scrollToSection}
        featuresRef={featuresRef}
        whyChooseUsRef={whyChooseUsRef}
        pricingRef={pricingRef}
        homeRef={homeRef}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          width: '100%',
          minHeight: '100vh',
        }}
      >
        <style>
          {`
          .custom-scrollbar {
            max-height: 230px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: rgba(139, 164, 255, 1) rgba(241, 241, 241, 1);
          }

          .custom-scrollbar::-webkit-scrollbar {
            width: 5px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background: #e4efef;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #354150;
            border-radius: 2px;
          }
        `}
        </style>
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'left',
            padding: { xs: '0', md: '0' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              m: 1,
              width: { md: '90%', lg: '65%', xs: '90%' },
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '32px',
                fontWeight: 500,
                color: 'rgba(2, 17, 72, 0.9)',
              }}
            >
              Welcome to Optigrit!
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 400,
                color: 'rgba(120, 120, 120, 1)',
                mt: '12px',
                mb: '20px',
              }}
            >
              Start by creating or joining an organization to begin managing your team and projects.
            </Typography>
          </Box>
          <Box
            sx={{
              width: { md: '90%', lg: '65%', xs: '90%' },
              mb: 4,
            }}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                gap: '12px',
                width: '100%',
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search by name or email"
                value={orgName}
                onChange={(e) => validateName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    getOrgSearch(orgName);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: isSearchActive && (
                    <InputAdornment position="end">
                      <Clear sx={{ cursor: 'pointer' }} onClick={handleClearSearch} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: '8px',
                  border: '1px solid rgba(235, 235, 235, 1)',
                  '& fieldset': { border: 'none' },
                  fontSize: '14px',
                  width: '60%',
                  '& .MuiOutlinedInput-root': {
                    '& input': {
                      padding: '8px 12px',
                    },
                  },
                }}
              />
              <FormControl sx={{ width: '40%' }}>
                <Select
                  value={org}
                  onChange={(e) => setOrg(e.target.value)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                          Select Type Organization
                        </Typography>
                      );
                    }
                    return selected;
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: '8px',
                      },
                    },
                  }}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid rgba(235, 235, 235, 1)',
                    fontSize: '14px',
                    '& .MuiSelect-select': {
                      padding: '8px 12px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  IconComponent={(props) => (
                    <ArrowDropDown {...props} sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                  )}
                >
                  {orgOption.map((x) => (
                    <MenuItem
                      value={x.value}
                      key={x.id}
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {x.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
          {loader ? (
            <Box
              sx={{
                overflowY: 'scroll',
                height: '230px',
                alignItems: 'flex-start',
                width: { md: '90%', lg: '65%', xs: '90%' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Stack
              gap={'12px'}
              className="custom-scrollbar"
              sx={{
                overflowY: 'scroll',
                height: '230px',
                alignItems: 'flex-start',
                width: { md: '90%', lg: '65%', xs: '90%' },
              }}
            >
              {orgData && orgData.length > 0 ? (
                <>
                  {orgData &&
                    orgData.map((x) => {
                      if (org === 'Owned' && x.isCreator) {
                        return (
                          <Stack
                            sx={{
                              flexDirection: 'row',
                              borderRadius: '8px',
                              width: { xs: '90%', md: '93%' },
                              cursor: 'pointer',
                              border: '1px solid rgba(225, 225, 225, 1)',
                              p: '12px',
                            }}
                            key={x.id}
                            onClick={() => {
                              localStorage.setItem('orgName', x.name);
                              navigate(`/org/${x.name}/dashboard`);
                            }}
                          >
                            <Stack sx={{ justifyContent: 'center' }}>
                              {x.logoLink ? (
                                <Avatar
                                  src={x.logoLink}
                                  sx={{
                                    width: '82px',
                                    height: '80px',
                                    borderRadius: '4px',
                                  }}
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    width: '82px',
                                    height: '80px',
                                    borderRadius: '4px',
                                    fontSize: { xs: '25px', md: '35px' },
                                  }}
                                >
                                  {x.name.charAt(0)}
                                </Avatar>
                              )}
                            </Stack>
                            <Stack sx={{ width: '100%', overflow: 'hidden' }}>
                              <Box sx={{ pl: '8px' }}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    marginRight: '8px',
                                    color: 'rgba(2, 17, 72, 0.9)',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {x.name}
                                </Typography>

                                <Typography
                                  sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    marginRight: '8px',
                                    color: 'rgba(62, 67, 75, 0.9)',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {x.description}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  mt: 0.5,
                                  flexDirection: { xs: 'column', md: 'row' },
                                  alignItems: { xs: 'flex-start', md: 'center' },
                                  gap: 1,
                                }}
                              >
                                <Stack
                                  sx={{
                                    pl: '8px',
                                    pt: '8px',
                                    gap: '8px',
                                    overflow: 'hidden',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Stack
                                    sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}
                                  >
                                    <Stack direction={'row'} gap={'4px'}>
                                      {x.usersData?.slice(0, 3).map((user, index) =>
                                        user.profilePhotoLink ? (
                                          <Avatar
                                            src={user.profilePhotoLink}
                                            sx={{ width: '16px', height: '16px' }}
                                            key={index}
                                          />
                                        ) : (
                                          <Avatar
                                            sx={{
                                              width: '16px',
                                              height: '16px',
                                              textTransform: 'capitalize',
                                              fontSize: '10px',
                                              pt: '1px',
                                            }}
                                            key={index}
                                          >
                                            {user.firstName.charAt(0)}
                                          </Avatar>
                                        )
                                      )}
                                      <Typography
                                        sx={{
                                          fontFamily: 'Poppins',
                                          fontSize: '12px',
                                          fontWeight: 400,
                                          color: 'rgba(62, 67, 75, 1)',
                                          ml: 0.5,
                                        }}
                                      >
                                        {x.usersData.length > 0 && `${x.usersData.length} members`}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Stack>
                                <Chip
                                  label={x.planName}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    flexShrink: 0,
                                    color: 'rgba(26, 40, 90, 1)',
                                    border: '0.5px solid rgba(105, 138, 255, 1)',
                                    background: 'rgba(245, 247, 255, 1)',
                                    borderRadius: '4px',
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    padding: '0px 12px',
                                    height: '24px',
                                    ml: '8px',
                                  }}
                                />
                              </Box>
                            </Stack>
                          </Stack>
                        );
                      }
                      if (org === 'Joined' && !x.isCreator) {
                        return (
                          <Stack
                            sx={{
                              flexDirection: 'row',
                              borderRadius: '8px',
                              width: { xs: '90%', md: '93%' },
                              cursor: 'pointer',
                              border: '1px solid rgba(225, 225, 225, 1)',
                              p: '12px',
                            }}
                            key={x.id}
                            onClick={() => {
                              localStorage.setItem('orgName', x.name);
                              navigate(`/org/${x.name}/dashboard`);
                            }}
                          >
                            <Stack sx={{ justifyContent: 'center' }}>
                              {x.logoLink ? (
                                <Avatar
                                  src={x.logoLink}
                                  sx={{
                                    width: '82px',
                                    height: '80px',
                                    borderRadius: '4px',
                                  }}
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    width: '82px',
                                    height: '80px',
                                    borderRadius: '4px',
                                    fontSize: { xs: '25px', md: '35px' },
                                  }}
                                >
                                  {x.name.charAt(0)}
                                </Avatar>
                              )}
                            </Stack>
                            <Stack sx={{ width: '100%', overflow: 'hidden' }}>
                              <Box sx={{ pl: '8px' }}>
                                <Typography
                                  sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    marginRight: '8px',
                                    color: 'rgba(2, 17, 72, 0.9)',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {x.name}
                                </Typography>

                                <Typography
                                  sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    marginRight: '8px',
                                    color: 'rgba(62, 67, 75, 0.9)',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {x.description}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  mt: 0.5,
                                  flexDirection: { xs: 'column', md: 'row' },
                                  alignItems: { xs: 'flex-start', md: 'center' },
                                  gap: 1,
                                }}
                              >
                                <Stack
                                  sx={{
                                    pl: '8px',
                                    pt: '8px',
                                    gap: '8px',
                                    overflow: 'hidden',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Stack
                                    sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}
                                  >
                                    <Stack direction={'row'} gap={'4px'}>
                                      {x.usersData?.slice(0, 3).map((user, index) =>
                                        user.profilePhotoLink ? (
                                          <Avatar
                                            src={user.profilePhotoLink}
                                            sx={{ width: '16px', height: '16px' }}
                                            key={index}
                                          />
                                        ) : (
                                          <Avatar
                                            sx={{
                                              width: '16px',
                                              height: '16px',
                                              textTransform: 'capitalize',
                                              fontSize: '10px',
                                              pt: '1px',
                                            }}
                                            key={index}
                                          >
                                            {user.firstName.charAt(0)}
                                          </Avatar>
                                        )
                                      )}
                                      <Typography
                                        sx={{
                                          fontFamily: 'Poppins',
                                          fontSize: '12px',
                                          fontWeight: 400,
                                          color: 'rgba(62, 67, 75, 1)',
                                          ml: 0.5,
                                        }}
                                      >
                                        {x.usersData.length > 0 && `${x.usersData.length} members`}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Stack>
                                <Chip
                                  label={x.planName}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    flexShrink: 0,
                                    color: 'rgba(26, 40, 90, 1)',
                                    border: '0.5px solid rgba(105, 138, 255, 1)',
                                    background: 'rgba(245, 247, 255, 1)',
                                    borderRadius: '4px',
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    padding: '0px 12px',
                                    height: '24px',
                                    ml: '8px',
                                  }}
                                />
                              </Box>
                            </Stack>
                          </Stack>
                        );
                      }
                    })}
                  {org === 'Owned' && !orgError && owned?.length === 0 && (
                    <Typography sx={{ fontSize: '18px', fontWeight: '600', color: 'white' }}>
                      No organizations to show here
                    </Typography>
                  )}
                  {org === 'Joined' && !orgError && joined?.length === 0 && (
                    <Typography sx={{ fontSize: '18px', fontWeight: '600', color: 'white' }}>
                      No organizations to show here
                    </Typography>
                  )}
                  {orgError && (
                    <Typography sx={{ fontSize: '18px', fontWeight: '600', color: 'white' }}>
                      {orgError}
                    </Typography>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    backgroundColor: 'rgba(241, 244, 255, 1)',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    gap: 1,
                    p: 4,
                    borderRadius: '10px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontWeight: '500',
                        color: 'rgba(2, 17, 72, 1)',
                        mb: 1,
                      }}
                    >
                      It looks like you haven’t joined or created any organizations yet.
                    </Typography>{' '}
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: 'rgba(62, 67, 75, 1)',
                      }}
                    >
                      Get started by creating a new organization or joining one with an invite
                      code."
                    </Typography>
                  </Box>

                  <img src={no_data_img} alt="" />
                </Box>
              )}
            </Stack>
          )}

          <Box
            sx={{
              width: { md: '90%', lg: '65%', xs: '90%' },
              mt: 3,
              mb: 2,
            }}
          >
            <Button variant="contained" fullWidth onClick={handleCreateOrganization}>
              Create a New Organization
            </Button>
            <Button variant="outlined" fullWidth sx={{ mt: 2 }} onClick={handleJoinOrganization}>
              Join with an Invite Code
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            backgroundColor: 'rgba(235, 239, 255, 1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            padding: { xs: '30px 0', md: '0' },
          }}
        >
          <img
            alt=""
            src={startOrJoinImg}
            style={{
              maxWidth: '90%',
              height: 'auto',
              maxHeight: isSmallScreen ? '50%' : '80%',
            }}
          />
        </Box>
      </Box>
    </div>
  );
}
