import { useEffect, useState } from 'react';
import { Box, TextField, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { GroupAddOutlined, RefreshOutlined } from '@mui/icons-material';
import GroupChatWindow from './ChatComponents/GroupDetails/GroupChatWindow';
import { ChatApi } from '../../api/requests/chat/ChatApi';
import BigLoader from '../Skeleton/BigLoader';
import { handleAlert } from '../../utils/handleAlert';
import UserChatWindow from './ChatComponents/UserDetails/UserChatWindow';
import UserSearchList from '../SearchAndAdd/UserSearchList';
import { useTheme, useMediaQuery } from '@mui/material';
import ChatListCard from '../Card/ChatListCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import GetValidatedTokenData from '../../utils/helper';
import getRoleForOrganization from '../../utils/GetUserRoleInOrganization';
import { useDocumentTitle } from '../../utils/useDocumentTitle';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummaryWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '11px 16px 11px 0px',
  backgroundColor: '#EDF4FB',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    sx={{
      pr: 0,
    }}
    expandIcon={
      <Box
        className="expandIcon"
        onClick={(e) => {
          e.stopPropagation();
          props.onChange(e, !props.expanded);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          transition: 'background-color 0.3s ease',
          cursor: 'pointer',
          p: 0,
          '&:hover': {
            backgroundColor: '#698AFF',
            color: '#fff',
            borderRadius: '50%',
          },
        }}
      >
        <ArrowForwardIosSharpIcon sx={{ fontSize: '12px' }} />
      </Box>
    }
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#EDF4FB',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
  '&.Mui-expanded': {
    minHeight: '32px!important',
  },
  '&.MuiAccordionSummary-root': {
    minHeight: '32px!important',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme, height }) => ({
  padding: 0,
  overflowY: 'auto',
  height: height,
  transition: 'height 0.3s ease',
  '&:focus': {
    outline: 'none',
  },
}));

export default function ChatList({
  socket,
  setSelectedChat,
  usersList,
  setUsersList,
  selectedChat,
  groupList,
  setGroupList,
}) {
  useDocumentTitle('Chat');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [groupSearchInput, setGroupSearchInput] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState({
    panel1: false,
    panel2: false,
  });
  const [refreshChat, setRefreshChat] = useState(false);
  const [draftChat, setDraftChat] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const url = useParams();
  const drawerWidth = 200;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const currentUserInfo = GetValidatedTokenData();

  const [userRole, setUserRole] = useState('');

  const getAccordionHeight = (panel) => {
    if (expandedPanels.panel1 && expandedPanels.panel2) {
      return '32.5vh';
    } else if (expandedPanels[panel]) {
      if (isSmallScreen) {
        return '60.5vh';
      } else {
        return '65.4vh';
      }
    } else {
      return '0vh';
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpandedPanels((prevState) => ({
      ...prevState,
      [panel]: newExpanded,
    }));
  };

  const createGroupClick = () => {
    if (!expandedPanels.panel2) {
      setExpandedPanels({ ...expandedPanels, panel2: true });
      setOpen(!open);
    } else {
      setOpen(!open);
    }
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chatId = searchParams.get('chatid');
  const groupId = searchParams.get('groupid');

  useEffect(() => {
    if (chatId) {
      const chat = usersList.find((user) => user?.chatData?.id === chatId);
      if (chat) {
        setSelectedChat(chat);
      } else {
        setSelectedChat(true);
      }
      setExpandedPanels({ panel1: true, panel2: false });
    } else if (groupId) {
      const chat = groupList.find((group) => group?.chatData?.id === groupId);
      if (chat) {
        setSelectedChat(chat);
      }
      setExpandedPanels({ panel1: false, panel2: true });
    } else if (!chatId && !groupId) {
      setSelectedChat(null);
    }
  }, [chatId, groupId, groupList, usersList]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setRefreshChat(!refreshChat);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setIsRefreshing(false);
  };

  const handleChatClick = (chat) => {
    if (isSmallScreen) {
      const groupName = chat.chatData?.name && chat.chatData.name;
      const userName = chat.senderData?.username ? chat.senderData.username : chat.username;
      const groupId = chat.chatData?.id || chat.id;
      const userId = chat.chatData?.id || chat?.id;
      const newUrl =
        chat.chatData?.type === 'GROUP'
          ? `/org/${url.orgName}/chat/group/${groupId}/${groupName}`
          : `/org/${url.orgName}/chat/user/${userId}/${userName}`;
      navigate(newUrl);
    } else if (!isSmallScreen) {
      const groupId = chat.chatData?.id || chat.id;
      const userId = chat.chatData?.id ? chat.chatData?.id : 'newChatId';
      const newUrl =
        chat.chatData?.type === 'GROUP'
          ? `/org/${url.orgName}/chat?groupid=${groupId}&name=${chat.chatData?.name}`
          : `/org/${url.orgName}/chat?chatid=${userId}&name=${
              chat?.senderData?.username ? chat.senderData.username : chat.username
            }`;
      navigate(newUrl);
    }
  };

  const handleCreateGroup = (groupData) => {
    setSelectedChat(groupData);
    if (isSmallScreen) {
      const newUrl =
        groupData.name && `/org/${url.orgName}/chat/group/${groupData.id}/${groupData.name}`;
      navigate(newUrl);
    }
    if (!isSmallScreen) {
      const newUrl =
        groupData.name && `/org/${url.orgName}/chat?groupid=${groupData.id}&name=${groupData.name}`;
      navigate(newUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreateLoading(true);
    if (!groupName) {
      handleAlert('Group name is required', 'error');
      setCreateLoading(false);
      return;
    }
    try {
      const createGroupRequestBody = {
        name: groupName,
        description: groupDescription,
      };
      const response = await ChatApi.createGroup(createGroupRequestBody);
      getAllGroups();
      handleCreateGroup(response);
      handleAlert('Group created successfully', 'success');
    } catch (error) {
      handleAlert(error.message, 'error');
    } finally {
      setGroupName('');
      setGroupDescription('');
      setCreateLoading(false);
      setOpen(false);
    }
  };

  const getAllGroups = async () => {
    try {
      const response = await ChatApi.getGroups();
      setGroupList(response);
    } catch (error) {
      handleAlert('Something went wrong', 'error');
    }
    setLoading(false);
  };

  const getAllUsers = async () => {
    try {
      const response = await ChatApi.getUsers();
      setUsersList(response);
    } catch (error) {
      handleAlert('Something went wrong', 'error');
    }
  };
  const handleResetSearchUser = () => {
    setInput('');
  };

  const [handleFetchUser, setHandleFetchUser] = useState(true);
  useEffect(() => {
    getAllUsers();
    getAllGroups();
  }, [handleFetchUser, refreshChat]);
  socket.on('chat', (msg) => {
    if (msg?.refetchUserChats) {
      setHandleFetchUser(!handleFetchUser);
    }
  });

  useEffect(() => {
    const isInvalid = /^[, ]+$/.test(input) || input === '';
    if (isInvalid) {
      setInput('');
      setSearchedUsers([]);
      return;
    }

    const searchUsers = async () => {
      try {
        const response = await ChatApi.searchUser(input.toLowerCase());
        const lowerCaseInput = input.toLowerCase();
        const filteredUsers = response.filter((user) =>
          user.username.toLowerCase().includes(lowerCaseInput)
        );
        setSearchedUsers(filteredUsers);
      } catch (error) {
        handleAlert(error.message, 'warning');
      }
    };

    searchUsers();
  }, [input]);

  const handleAddition = (user) => {
    const isUserPresent = usersList.find((u) => u.senderData?.id === user.id);
    if (isUserPresent) {
      setSelectedChat(isUserPresent);
    } else {
      const newUser = {
        senderData: {
          id: user.id,
          username: user.username,
          profilePhotoLink: user.profilePhotoLink,
          newUser: true,
        },
      };
      setUsersList((prevUsers) => [newUser, ...prevUsers]);
      setDraftChat(newUser);
      setSelectedChat(newUser);
    }
    setInput('');
    setExpandedPanels((prevState) => ({
      ...prevState,
      panel1: true,
    }));
  };
  const filteredGroupList = groupList.filter((group) =>
    (group.chatData?.name ? group.chatData?.name : group.id)
      .toLowerCase()
      .includes(groupSearchInput.toLowerCase())
  );
  useEffect(() => {
    const getRoleData = async () => {
      const role = await getRoleForOrganization();
      setUserRole(role);
    };
    getRoleData();
  }, []);

  if (loading) {
    return (
      <Box>
        <BigLoader />
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 0,
        width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        height: isSmallScreen ? '90vh' : '100vh',
        borderBottom: '0.800px solid #D3D3D3',
      }}
      overflow={'hidden'}
    >
      <Box
        display="flex"
        sx={{ backgroundColor: '#f4f6ff!important', padding: { xs: '12px', md: '28px' } }}
        width="100%"
        height={'100vh'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}
          border={'0.800px solid #eeeff1'}
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            overflowY: 'auto',
            mr: { sm: '12px', md: '24px' },
            height: { xs: '98%', sm: '100%' },
            width: {
              xs: '100%',
              sm: 'auto',
              md: '500px',
            },
          }}
        >
          <Box display={'flex'}>
            <UserSearchList
              input={input}
              setInput={setInput}
              searchedUsers={searchedUsers}
              handleChatClick={handleChatClick}
              handleAddition={handleAddition}
              handleResetSearchUser={handleResetSearchUser}
              height={'30px'}
              width={(xs) => (xs ? '90%' : 'auto')}
            />
            <Tooltip
              title="Refresh chat"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -20],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton onClick={handleRefresh}>
                {isRefreshing ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'grey',
                    }}
                  />
                ) : (
                  <RefreshOutlined
                    sx={{
                      fontSize: '30px',
                      color: 'grey',
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>

          <Accordion expanded={expandedPanels.panel1} onChange={handleChange('panel1')}>
            <AccordionSummaryWrapper>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                <AccordionSummary
                  expanded={expandedPanels.panel1}
                  onChange={handleChange('panel1')}
                />
                <Box
                  padding={'5px 0px'}
                  sx={{ color: 'grey', flex:1, cursor: 'pointer' }}
                  onClick={() =>
                    setExpandedPanels({ ...expandedPanels, panel1: !expandedPanels.panel1 })
                  }
                >
                  Direct Message
                </Box>
              </Box>
            </AccordionSummaryWrapper>
            <AccordionDetails height={getAccordionHeight('panel1')}>
              <Box
                sx={{
                  overflowY: 'auto',
                  paddingX: {
                    xs: '10px',
                    sm: '0px',
                    backgroundColor: '#fff',
                  },
                  mb: 1,
                  mt: 1,
                  transition: 'height 0.3s ease',
                }}
                display="flex"
                flexDirection={'column'}
              >
                {usersList &&
                  usersList.map((chat, index) => (
                    <ChatListCard
                      key={index}
                      chat={chat}
                      handleChatClick={handleChatClick}
                      selectedChat={selectedChat}
                      senderId={chat.lastMessageData ? chat.lastMessageData.sender_id : null}
                      currentuser={currentUserInfo.id}
                      name={chat.senderData ? chat.senderData.username : chat.username}
                      profilePhotoLink={
                        chat.senderData ? chat.senderData.profilePhotoLink : chat.profilePhotoLink
                      }
                      latestMessage={
                        chat.lastMessageData
                          ? chat.lastMessageData.message
                          : chat.latestMessage
                          ? chat.latestMessage
                          : null
                      }
                      unreadMessagesCount={chat.unreadMessagesCount ? chat.unreadMessagesCount : 0}
                    />
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box width="100%" sx={{ borderRadius: '0 0 9px 9px', backgroundColor: '#fff' }}>
            <Accordion expanded={expandedPanels.panel2} onChange={handleChange('panel2')}>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                <AccordionSummaryWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      setExpandedPanels({ ...expandedPanels, panel2: !expandedPanels.panel2 })
                    }
                  >
                    <AccordionSummary
                      expanded={expandedPanels.panel2}
                      onChange={handleChange('panel2')}
                    />
                    <Box padding={'5px 0px'} sx={{ color: 'grey' }}>
                      Groups
                    </Box>
                  </Box>

                  <GroupAddOutlined
                    sx={{
                      fontSize: '1.5rem',
                      cursor: 'pointer',
                      color: 'grey',
                    }}
                    onClick={createGroupClick}
                  />
                </AccordionSummaryWrapper>
              </Box>
              <AccordionDetails height={getAccordionHeight('panel2')}>
                <Typography>
                  <Box
                    sx={{
                      overflowY: 'auto',
                      mt: 1,
                      paddingX: {
                        xs: '10px',
                        sm: '0px',
                        gap: '4px',
                      },
                    }}
                    height={{ xs: '70%', sm: '90%' }}
                    width="100%"
                    display="flex"
                    flexDirection={'column'}
                  >
                    {open && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: 1,
                          margin: '2px',
                          alignItems: 'center',
                        }}
                      >
                        <TextField
                          id="standard-basic"
                          label="Group Name"
                          variant="standard"
                          value={groupName}
                          fullWidth
                          onChange={(e) => setGroupName(e.target.value)}
                        />
                        <TextField
                          id="standard-basic"
                          label="Group Description"
                          variant="standard"
                          value={groupDescription}
                          fullWidth
                          onChange={(e) => setGroupDescription(e.target.value)}
                          sx={{ mt: 2 }}
                        />
                        <Button
                          variant="outlined"
                          sx={{
                            mt: 1.5,
                            width: '100%',
                          }}
                          onClick={handleSubmit}
                          disabled={createLoading}
                        >
                          {createLoading ? <CircularProgress size={24} /> : 'Create'}
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            mt: 1,
                            width: '100%',
                          }}
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}
                    {filteredGroupList &&
                      filteredGroupList.map((chat, index) => (
                        <ChatListCard
                          key={index}
                          chat={chat}
                          handleChatClick={handleChatClick}
                          selectedChat={selectedChat}
                          name={chat.chatData ? chat.chatData.name : chat.name}
                          currentuser={currentUserInfo.id}
                          senderId={chat.lastMessageData ? chat.lastMessageData.sender_id : null}
                          latestMessage={
                            chat.lastMessageData ? chat.lastMessageData.message : chat.latestMessage
                          }
                          unreadMessagesCount={
                            chat.unreadMessagesCount ? chat.unreadMessagesCount : 0
                          }
                        />
                      ))}
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        {!isSmallScreen ? (
          <Box width="100%" position={'relative'}>
            {selectedChat ? (
              selectedChat.chatData ? (
                selectedChat.chatData.type === 'GROUP' ? (
                  <GroupChatWindow
                    chat={selectedChat}
                    setSelectedChat={setSelectedChat}
                    groupList={groupList}
                    setGroupList={setGroupList}
                    socket={socket}
                    userRole={userRole}
                  />
                ) : (
                  <UserChatWindow
                    key={selectedChat.id}
                    chat={selectedChat}
                    setUserList={setUsersList}
                    setSelectedChat={setSelectedChat}
                    userList={usersList}
                    socket={socket}
                  />
                )
              ) : selectedChat.name ? (
                <GroupChatWindow
                  chat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  groupList={groupList}
                  setGroupList={setGroupList}
                  socket={socket}
                  userRole={userRole}
                />
              ) : (
                chatId &&
                draftChat && (
                  <UserChatWindow
                    setUserList={setUsersList}
                    socket={socket}
                    draftChat={draftChat}
                  />
                )
              )
            ) : (
              <Box
                sx={{
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  height: '100%',
                  color: '#888',
                }}
              >
                <p>Tap to open a chat</p>
              </Box>
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
